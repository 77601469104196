  #app{
    border-radius:3px;
    border: 1px solid #a16a6a;
    margin:15px;
    background-color:white;
  }
  
  .tab{
    min-height:50vh;
    width:100%;
    padding:25px;
    font-family:sans-serif;
    color:#444;
    text-align: center;
    h4{
      color: rgb(63, 81, 181);
    }
    h5{
      color: rgb(63, 81, 181);
    }
  }
  
  ul.inline{
    list-style:none;
    width: 100%;
    padding: 0;
    margin-bottom:0;
    text-align: center;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
    li{
      display:inline-block;
      margin-left:0;
      padding:10px;
      border-bottom:2px solid #eee;
      transition: all .5s;
      font-family:sans-serif;
      font-weight:300;
      cursor:pointer;
      color:#aaa;
      width: 25%;
      &.selected{
        border-bottom:2px solid #337ab7;
        color:#444;
        width: 25%;
      }
      
    }
  }

  @media (max-width:600px) {

    
    ul.inline{
      list-style:none;
      width: 100%;
      padding: 0;
      margin-bottom:0;
      text-align: center;
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 0px;
      li{
        display:inline-block;
        margin-left:0;
        padding:10px;
        border-bottom:2px solid #eee;
        transition: all .5s;
        font-family:sans-serif;
        font-size:small;
        cursor:pointer;
        color:#aaa;
        width: 33.3%;
        &.selected{
          border-bottom:2px solid #337ab7;
          color:#444;
          width: 33.3%;
        }
        
      }
    }
  
  }