.homepage-img{
    position: relative;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
     
}

.welcome-text{
    margin-top: 400px;  
    position: absolute;
    color: black;
    letter-spacing: 4px;
    word-spacing: 8px;
    font-size: 2rem;

}


@media (max-width:600px){

    .homepageitem{
        margin-top: 100px;
    }

    .welcometxt{
        font-size: 20px;
    }
    .homepage-img{
        justify-content: center;
        width: 100vw;      
    }
    
    
    .welcome-text{
        margin-top: 400px;
        position: absolute;
        color: black;
        letter-spacing: 2px;
        word-spacing: 4px;
        font-size: 2rem;
        width: 100vw;
    
    }    
}