

@media (max-width:600px) {
    .smalltextSize{
        font-size: 16px;
    }
    .headerText{
        font-size: 35px;
    } 
    .smalltext{
        font-size: 18px;
      } 
}