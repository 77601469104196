*{
    font-family: 'Open Sans Condensed';
    /* padding: 20px 60px; */
}

a{
    text-decoration: none;
    color: black;
}

*{
    box-sizing: border-box;
}
.app__body{
    display: flex;
    margin-top: 0px;
    margin-left: 30px;
    height: 80vh;
    width: 95vw;
    background-color: #ededed;
    box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.6);
  }