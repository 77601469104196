.col-md-4 {
    margin-bottom: 30px;
  }
  
  .card {
    padding: 30px;
    border: solid 1px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.card-img-top{
    display: flex;
    flex-direction: column;
    align-items: center  
}
  .card:hover {
    box-shadow: 0 0 12px 3px rgba(241, 227, 227, 0.08);
  }
  .card-title {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  .card-text {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    }
  .card-text .fa {
    font-size: 26px;
  }
  .avatar {
    width: 140px;
    margin: auto;
  }
  .avatar img {
    border: solid 6px transparent;
    border-radius: 50%;
    width:150px;
  }
  

  
@media (max-width:600px) {
 
  .card {
    padding: 3px;
    border: solid 1px rgba(0, 0, 0, 0.08);
    cursor: pointer;
} 

.smalltext{
  font-size: 18px;
}
}