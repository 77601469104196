.stepper-wrapper-horizontal {
    display: flex;
    justify-content: space-between;

    .step-wrapper {
        width: 26%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .step-number {
        border-radius: 50%;
        border: 1px solid grey;
        width: 20px;
        height: 20px;
        padding: 3px;
        text-align: center;
        margin-bottom: 1.2rem;
    }

    .divider-line {
        height: 1px;
        background-color: #bdbdbd;
        position: absolute;
        top: 20%;
        left: 70%
    }

    .divider-line-2 {
        width: 296%
    }

    .divider-line-3 {
        width: 125%
    }

    .divider-line-4 {
        width: 70%
    }

    .divider-line-5 {
        width: 60%
    }
}

.stepper-wrapper-vertical {
    .step-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;
        position: relative;
    }

    .step-number {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        padding: 4.5px 4px 4px;
        text-align: center;
        font-size: 95%;
    }

    .step-description {
        margin-left: 1.2rem;
        padding-bottom: 3px;
    }

    .divider-line {
        height: 17px;
        width: 1px;
        background-color: #bdbdbd;
        position: absolute;
        top: 100%;
        left: 2.4%
    }
}

.step-number-selected {
    // background: purple;
    border: 1px solid #bdbdbd;
    color: #fff;

}

.step-number-disabled {
    border: 1px solid #838383
}

.step-description-active {
    font-weight: bold;
}