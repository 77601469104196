  .Kyc {
    position: relative;
    font-family: sans-serif;
    text-align: center;
    width: 100vw;
    min-height: 100vh;
  
  }
  .Kyc::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.2;
    z-index: -1;
  }
  .wizard {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 5vh 0;
    z-index: 1;
    pointer-events: all;
  }
  