//   .stepper-container-vertical {
//     border: 1px solid rgb(63, 81, 181);
//     box-shadow: 0 0 13px #333;
//     border-radius: 3px;
//     margin: 40px auto;
//     padding: 30px;
//     width: 350px;
//     height: auto;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//   }

  // > 768

  
   @media (min-width: 320px) {

    .stepper-container-vertical {
        border: 1px solid rgb(63, 81, 181);
        box-shadow: 0 0 13px #333;
        border-radius: 3px;
        margin: 40px auto;
        padding: 30px;
        width: 320px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    
    
  }

  @media (min-width: 700px) {

    .stepper-container-vertical {
        border: 1px solid rgb(63, 81, 181);
        box-shadow: 0 0 13px #333;
        border-radius: 3px;
        margin: 40px auto;
        padding: 30px;
        width: 350px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    
    
  }
  // > 992
  @media (min-width: 1024px) {
    .stepper-container-vertical {
        border: 1px solid rgb(63, 81, 181);
        box-shadow: 0 0 13px #333;
        border-radius: 3px;
        margin: 40px auto;
        padding: 30px;
        width: 350px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    
  }
  // > 1200
  @media (min-width: 1200px ) {
    .stepper-container-vertical {
        border: 1px solid rgb(63, 81, 181);
        box-shadow: 0 0 13px #333;
        border-radius: 3px;
        margin: 40px auto;
        padding: 30px;
        width: 350px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    
  }
  