
  .header {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }  

  .logo-container {
    height: 100%;
    width: 70px;
    padding: 25px;
  }

    

  .options {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

   .option {
      padding: 10px 15px;
      cursor: pointer;
      font-family: 'Open Sans Condensed';
      color: black;
      text-decoration: none !important;
      }

    
.navbar {
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  .profile-bars {
    font-size: 2rem;
    background: none;
    text-decoration:none;
  }
  
  .nav-menu {
    background-color: #060b26;
    width: 300px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 6;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 55px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .myprofile-text{
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
   
    text-decoration: none !important

  }
  .myprofile-text link:hover{
    text-decoration: none !important
  }
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: #060b26;
    margin-top: 20px;
  }
  
  span {
    margin-left: 16px;
  }

  link:hover, link:visited, link:link, link:active
{
    text-decoration: none;
}
  

.wide-nav{
  display: flex;
} 
.short-nav{
  display:none;
}

@media (max-width: 600px) {
  .wide-nav{
    display: none;
  } 
  .short-nav{
    display:flex ;    
  }
}