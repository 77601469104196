.chat{
    flex: 0.75;
    display: flex;
    flex-direction: column;
}

.chat__header{
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgrey;
}

.chat__headerInfo{
    flex: 1;
    padding-left: 20px;
}

.chat__headerInfo > h3{
    margin-bottom: 3px;
    font-weight: 500;
}

.chat__headerInfo > p{
    color: rgb(24, 22, 22);
}

.chat__headerRight{
    justify-content: space-between;
    min-width: 60px;
}

.chat__body{
    flex: 1;
    background-repeat: repeat;
    background-position: center;
    padding: 30px;
    overflow: scroll;
}
.emoji-mart {
    margin-bottom: 500px;
}
.chat__message{
    position: relative;
    font-size: 16px;
    padding: 10px;
    background-color: rgb(250, 244, 244);
    border-radius: 10px;
    width: fit-content;
    margin-bottom: 34px;
}

.chat__receiver{
    margin-left: auto;
    color: #fff;
    background-image: linear-gradient(#3f51b5,#3f51b5);
}

.chat__name{
    position: absolute;
    top: -15px;
    font-weight: 800;
    font-size: 10px;
}

.chat__timestamp{
    margin-left: 10px;
    font-size: xx-small;
}

.chat__footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    border-top: 1px solid lightgrey;
}

.chat__footer > form {
    flex: 1;
    display: flex;

}

.chat__footer > form > input{
    flex: 1;
    border-radius: 30px;
    padding: 10px;
    border: none;
}

.chat__footer > .MuiSvgIcon-root{
    padding: 10px;
    color: gray;
}

.sendButton{
    color: gray;
    padding: 8px;
    display: none;
}