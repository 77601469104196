.sidebarChat{
    display: flex;
    padding: 12px;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
}

.sidebarChat:hover{
    /* background-image: linear-gradient(#FF4E50,#F9D423); */
}

a{
    text-decoration: none;
    color: black;
}

.sidebarChat__heading{
    font-size: 20px;
    margin-bottom: 8px;

}

.sidebarChat__info{
    margin-left: 15px;
}

.sidebarChat__addChat{
    display: flex;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
    justify-content: center;

}

.sidebarChat__addChat:hover {
    /* background-image: linear-gradient(#00d2ff,#3a7bd5); */
}

.addchaticon{
    margin-top: 5.75px;
    margin-left: 4%;
}