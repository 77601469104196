
.floating-menu {
  position: absolute;
  right: 8px;
  bottom: 18px;
  height: 42px;
  text-align: center;
  width: 162px;
  /*transition: height 250ms ease-in-out;*/
}

.floating-menu.open {
    width: 162px;
  height: 335px;
  overflow: hidden;
  text-align: center;
}

.floating-menu.open .floating-menu-item {
  margin-top: 8px;
}

.floating-menu-item label{
  display: inline-block;
  margin-right: 8px;
  padding: 4px 10px;
  border-radius: 12px;
  background: rgba(0,0,0,.25);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.floating-menu.open .floating-menu-item label {
  opacity: 0.4;
  width: 62px;

}

.floating-menu-icon{
  display: inline-block;
  width: 62px;
  height: 42px;
  line-height: 42px;
  vertical-align: middle;
  background: #e53935;
  border-radius: 50%;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,.2);
  text-align: center;
}

.floating-menu-icon .material-icons {
  font-size: 32px;
  color: #fff;
  vertical-align: middle;
}
