.wrapper{
    height: 70vh;
    font-size: 1.2rem;
float: left;
}
p,a, h1{
    padding:0.2rem;
    display:block;
}



@media (max-width:600px) {
    .smalltextSize{
        font-size: 14px;
    }
    .headerText{
        font-size: 20px;
    }
    
 
    .smallWidth{
        
        width: 100%;
    }
    .buttonmargin{
        margin-right: 5%;
    }
}

.card{
    background-color: #fff;
    border-radius: 0.2rem;
    box-shadow: 0px 0px 0.1rem rgba(0,0,0,0.3), 0px 0.2rem 0.2rem rgba(0,0,0,0.3);
    overflow:auto;
}


.mat_list{
    list-style-type: none;
    display:block;
    height: 100%;

}
.mat_list>li{
    margin-top:-1px;
    width:100%;
    max-height: 100%;
    transition: max-height 0.5s;
    overflow: hidden;
}
.mat_list>li::before{
    content: "";
    width: 75%;
    display:block;
    margin:0 auto;
    border-top:1px solid rgba(0,0,0,0.3);
    align-items: center;
}
.mat_list>li.hide{
    max-height:0px;
    align-items: center;
}
.mat_list_title{
    background-color: #3f51b5;
    color: rgba(255,255,255,0.75);
    text-align: center;
    font-size: 30px;
}
.mat_list_title[style*="background"]{
    height: 10rem;
    position:relative;
    background-size: cover;
    background-position: center center;
}
.mat_list_title>h1{
    font-size: 2rem;
    font-weight: 100;
}
.mat_list_title[style*="background"]>h1{
    position:absolute;
    bottom:0px;
    background-color: rgba(0,0,0,0.75);
}