

.node__root > circle{
    fill:greenyellow
}
.node__branch > circle{
    fill: aqua;
}
.node__leaf > circle{
    margin:100px;
    fill:red
}

.chart-container {
    display: flex;
    flex-direction: column;
  }
  
@media (min-width: 900px) {
    .chart-container {
      flex-direction: row;
    }
  }


@media (max-width:600px) {
  .smallwidth{
    width: 220px;
    margin: 0 auto;
  }
}  