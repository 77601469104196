
.sidebar{
    flex: 0.25;
    display: flex;
    flex-direction: column;
}

.sidebar__SearchContainer{
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 35px;
    border-radius: 20px;

}

.sidebar__SearchContainer > input{
border: none;
margin-left: 10px;
width: 100%;

}

.sidebar__SearchContainer > .MuiSvgIcon-root{
    color:black;
    padding: 1px;

}

.sidebar__search{
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    height: 39px;
    padding: 10px;
}

.sidebar__header{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-right: 1px solid lightgrey;
}

.sidebar__headerRight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 30vh;
}

.sidebar__headerRight > .MuiSvgIcon-root {

    margin-right: 2vw;
    font-size: 24px !important;
}

.sidebar__chat{
    flex: 1;
    overflow: scroll;
}